import { template as template_17dc705133a648c5a848825120397ebc } from "@ember/template-compiler";
const FKText = template_17dc705133a648c5a848825120397ebc(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
