import { template as template_76b54a30d3ab4623ab6aa26b4ec768d7 } from "@ember/template-compiler";
import icon from "discourse/helpers/d-icon";
const InputTip = template_76b54a30d3ab4623ab6aa26b4ec768d7(`
  <div
    class="tip {{if @validation.failed 'bad' 'good'}}"
    id={{@id}}
    ...attributes
  >
    {{#if @validation.reason}}
      {{icon (if @validation.failed "xmark" "check")}}
      {{@validation.reason}}
    {{/if}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default InputTip;
