import { template as template_2326bafea9494803b7f4ca41d083d4ef } from "@ember/template-compiler";
const FKControlMenuContainer = template_2326bafea9494803b7f4ca41d083d4ef(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
